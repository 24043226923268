export default {
  data() {
    return {
      userPermissions: [],
    };
  },
  methods: {
    loadPermissions(permission) {
      this.userPermissions = JSON.parse(
        localStorage.getItem("permissionsData")
      );
      var pkg = this.userPermissions.find((p) => p == permission);

      var v1 = pkg == permission;

      return v1;
    },
  },
};
